<template>
    <div>
        <tt-editor v-if="modeEdit" :value="data" @input="changeText" ></tt-editor>
        <div v-else v-html="data"></div>
    </div>
</template>
<script>
import ttEditor from './tt-editor.vue'

export default {
    name:"text-tt",
    components:{
    ttEditor
    },
    props:{
        data:String,
        modeEdit:Boolean
    },
    methods:{
        changeText(payload){
            this.$emit('changeText',payload)
        }
    }
}
</script>